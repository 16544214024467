<template>
    <b-card title="Discount Restriction">
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Restrict Discount To (% Maximum)">
                        <b-form-input placeholder="Please Enter Restrict Discount To (% Maximum)"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Restrict Discount To Flat (Maximum Amount)">
                        <b-form-input placeholder="Please Enter Restrict Discount To Flat (Maximum Amount)"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-checkbox checked="true" name="check-button" switch>
                        Apply Discount Restriction
                    </b-form-checkbox>
                </b-col>
                <b-col md="3">
                    <b-button variant="primary">
                        <feather-icon icon="SaveIcon" class="mr-50" />Update
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BCol, BRow, BForm, BFormInput, BFormGroup, BFormCheckbox, BButton
} from 'bootstrap-vue'

export default {
    components: {
        BCard, BCol, BRow, BForm, BFormInput, BFormGroup, BFormCheckbox, BButton
    }
}
</script>